import React from "react"
import {
  formatDateTime,
  isValidDate,
  formatDate,
  formatTime,
  formatDayAndDate,
} from "../utils/datetime"
import CalendarIcon from "../../content/assets/zondicons/calendar.svg"
import { IconBox } from "./blocks"

import moment from "moment"
import "moment/locale/de"

moment.locale("de")

export const Date = ({ datetime, className = "" }) => (
  <div className={className}>{formatDate(datetime)}</div>
)

export const DayAndDate = ({ datetime, className = "" }) => (
  <IconBox icon={<CalendarIcon className={className} />}>
    <div className={`text-sm text-primary-600`}>
      {formatDayAndDate(datetime)}
    </div>
  </IconBox>
)

export const DateTime = ({ start, end }) => (
  <IconBox icon={<CalendarIcon />}>
    <div className="text-sm text-primary-600 inline-block">
      {isValidDate(end) ? (
        <div className="inline-block">
          <div className="inline-block">
            {`${formatDateTime(start)}`}&nbsp;{"\u2013"}&nbsp;
          </div>
          <div className="inline-block">
            {moment(end).isSame(start, "day")
              ? formatTime(end)
              : formatDateTime(end)}
            &nbsp;
          </div>
        </div>
      ) : (
        <div className="inline-block">{`${formatDateTime(start)}`}&nbsp;</div>
      )}
    </div>
  </IconBox>
)

/*
export const DateTime2 = ({ start, end }) => (
  <div className="text-sm text-primary-600 inline-block fill-current">
    <CalendarIcon className="h-4 inline-block mr-2" />
    {end && end !== "Invalid date" ? (
      <div className="inline-block">
        <div className="inline-block">
          {`${formatDateTime(start)} \u2013`}&nbsp;
        </div>
        <div className="inline-block">{`${formatDateTime(end)}`}&nbsp;</div>
      </div>
    ) : (
      <div className="inline-block">{`${formatDateTime(start)}`}&nbsp;</div>
    )}
  </div>
)
*/
