import moment from "moment"
import "moment/locale/de"

moment.locale("de")

export const isValidDate = datetime => datetime && datetime !== "Invalid date"

export const formatDateTime = datetime =>
  moment(datetime)
    .locale("de")
    .format("dddd, D. MMMM YYYY, HH:mm")

export const formatDate = datetime =>
  moment(datetime)
    .locale("de")
    .format("D. MMMM YYYY")

export const formatDayAndDate = datetime =>
  moment(datetime)
    .locale("de")
    .format("dddd, D. MMMM YYYY")

export const formatTime = datetime =>
  moment(datetime)
    .locale("de")
    .format("HH:mm")

export const slugifyDateTime = datetime =>
  moment(datetime)
    .locale("de")
    .format("YYYY-MM-DD-HH-mm")
